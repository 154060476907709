// In this file you can overwrite scss color variables with css variables

.ribbon-primary {
    background: var(--theme-default);
}

.ribbon-primary.ribbon-clip:before {
    border-top-color: var(--theme-default);
    border-right-color: var(--theme-default);
}

.btn-outline-primary {
    border-color: var(--theme-default) !important;
    color: var(--theme-default) !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
    background-color: var(--theme-default-hover) !important;
    border-color: var(--theme-default-hover) !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
    background-color: var(--theme-default-hover) !important;
    border-color: var(--theme-default-hover) !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
    background-color: var(--theme-secondary-hover) !important;
    border-color: var(--theme-secondary-hover) !important;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "custom";
@import "overwrite";
// @import "vendors/chartist/chartist";
@import "vendors/themify.scss";
// @import "vendors/whether-icon.scss";
// @import "vendors/icofont.scss";
// @import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
// @import "vendors/sticky/sticky";
// @import "vendors/todo/todo";

// Datepicker
@import "~react-datepicker/dist/react-datepicker.css";

// Timepicker
// @import "~react-times/css/material/default.css";
// @import "~react-times/css/classic/default.css";

// Typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.css";

//  Dropzone
// @import "~react-dropzone-uploader/dist/styles.css";

// Drag&Drop
// @import "~react-dragula/dist/dragula.css";

@import '@asseinfo/react-kanban/dist/styles.css';

// ReactToastify
// @import "~react-toastify/dist/ReactToastify.css";

//  ImageCrop
// @import "~react-image-crop/dist/ReactCrop.css";

//  calendar
@import "~react-big-calendar/lib/css/react-big-calendar.css";
// react-big-calendar/lib/css/react-big-calendar.css
// @import "~sweetalert2/src/sweetalert2.scss";



// @import "~@fullcalendar/daygrid/main.css";
// @import "~@fullcalendar/timegrid/main.css";



//  Timeline
// @import "~react-vertical-timeline-component/style.min.css";

// Mde EDitor
// @import "~easymde/dist/easymde.min.css";

// chat emoji
// @import "~emoji-mart/css/emoji-mart.css";

// Map
// @import "~leaflet/dist/leaflet.css";

// Image gallery
// @import "~react-image-lightbox/style.css";

//image upload
// @import "~react-perfect-scrollbar/dist/css/styles.css";
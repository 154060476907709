/**=====================
    78. Sidebar CSS Start
==========================**/
@keyframes pagebody {
    from {
      opacity: 0;
      transform: scale3d(0.95, 0.95, 0.95) translate(-20px, -100px);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  %sidebar-after {
    position: absolute;
    content: "";
    top: 48%;
    width: 6px;
    border-top: 2px solid #595c60;
  }
  
  %horizontal-after {
    position: absolute;
    content: "";
    top: 49%;
    width: 5px;
    height: 1px;
    border-radius: 100%;
    background-color: #59667a;
  }
  
  %common {
    border-left-color: $dark-color;
    transition: $sidebar-transition;
  }
  
  %for-animated-hover {
    box-shadow: $main-header-shadow;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0;
  }
  
  %for-animated-hover-box {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    border-radius: 5px;
    overflow: hidden;
  
    &:before {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $white;
      content: "";
      top: -7px;
      position: absolute;
      left: 10px;
      z-index: 2;
    }
  
    &:after {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $light-background;
      content: "";
      top: -7px;
      position: absolute;
      left: 10px;
      z-index: 1;
    }
  }
  
  %sidebar-icon {
    display: block;
    transform: rotateX(0deg);
    z-index: 1;
    background-color: $white;
    color: $light-background;
    font-size: 14px;
    opacity: 1;
    margin-top: -70px;
    padding: 10px 0;
    text-align: left;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    position: absolute;
    width: 245px;
    margin-left: 115px;
  }
  
  .page-wrapper {
    position: relative;
    &.compact-sidebar{
      &.compact-small{
        &.material-icon{
          .page-header{
            .left-header, .right-header {
              .mega-menu{
                .nav-link{
                  span{
                    color: $white;
                  }
                }
              }
            }
          }
        }
      } 
    }
    .page-header {
      max-width: 100vw;
      position: $main-header-position;
      top: $main-header-top;
      z-index: $main-header-z-index;
      transition: $sidebar-transition;
      background-color: $white;
      box-shadow: $main-header-shadow;
  
      .header-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        padding: $header-wrapper-padding;
        position: relative;
  
        .search-full {
          background-color: $white;
          position: absolute;
          right: 0;
          z-index: 1;
          height: 0;
          width: 0;
          transform: scale(0);
          transition: all 0.3s ease;
  
          .ProfileCard {
            padding: 10px;
            border: 1px solid #ccc !important;
            margin-bottom: 10px;
            border-radius: 10px;
          }
  
          &.open {
            height: 100%;
            width: 100%;
            animation: zoomIn 0.5s ease-in-out;
            transform: scale(1);
            transition: all 0.3s ease;
          }
  
          input {
            line-height: 65px;
            padding-left: 60px;
            width: 102% !important;
  
            &:focus {
              outline: none !important;
            }
          }
  
          .form-group {
            .close-search {
              position: absolute;
              font-size: 15px;
              right: 30px;
              top: 30px;
              color: $dark-gray;
              cursor: pointer;
            }
  
            &:before {
              position: absolute;
              left: 30px;
              top: 26px;
              content: "\f002";
              font-size: 16px;
              color: $dark-gray;
              font-family: FontAwesome;
            }
          }
        }
  
        .nav-right {
          justify-content: flex-end;
  
          ul {
            li {
              svg {
                vertical-align: $header-wrapper-nav-icon-align;
                width: $header-wrapper-nav-icon-size;
  
                path {
                  color: $header-wrapper-nav-icon-color;
                }
              }
            }
          }
  
          &.right-header {
            ul {
              li {
                .mode {
                  cursor: pointer;
                  width: 20px;
                  text-align: center;
  
                  i {
                    font-size: 20px;
                    font-weight: 600;
                    transition: all 0.3s ease;
  
                    &.fa-lightbulb-o {
                      font-weight: 500;
                      font-size: 22px;
                      transition: all 0.3s ease;
                      animation: zoomIn 300ms ease-in-out;
                    }
                  }
                }
  
                .header-search {
                  cursor: pointer;
                }
  
                .profile-media {
                  img {
                    width: 35px;
                    height: 35px;
                  }
  
                  .media-body {
                    margin-left: 15px;
  
                    span {
                      font-weight: 500;
                    }
  
                    p {
                      font-size: 12px;
                      line-height: 1;
                      color: rgba($theme-body-font-color, 0.7);
                    }
                  }
                }
              }
            }
          }
  
          >ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
  
            >li {
              padding: 6px 10px;
              margin-right: 5px;
              display: inline-block;
              position: relative;
  
              .badge {
                position: absolute;
                right: -5px;
                top: -6px;
                padding: 2px 4px;
                font-size: 11px;
                font-weight: 700;
                font-family: work-Sans, sans-serif;
              }
  
              span {
                ul {
                  left: inherit;
                  right: -10px;
                  width: 130px;
  
                  &:before,
                  &:after {
                    left: inherit;
                    right: 10px;
                  }
  
                  li {
                    display: block;
  
                    a {
                      font-size: 14px;
                      color: $dark-color;
  
                      i {
                        margin-left: 10px;
                        font-size: 13px;
                        color: $dark-color;
                      }
                    }
                  }
                }
              }
            }
  
            .flag-icon {
              font-size: 16px;
            }
          }
  
          .onhover-show-div {
            width: 330px;
            top: 50px;
            right: 0;
            left: unset;
  
            .dropdown-title {
              padding: 20px;
              text-align: center;
              border-bottom: 1px solid rgba(145, 142, 153, 0.3);
            }
  
            ul {
              padding: 15px;
            }
  
            li {
              padding: 15px;
  
              p {
                margin-bottom: 0;
                font-size: 14px;
                letter-spacing: 0.3px;
              }
  
              &+li {
                margin-top: 15px;
                border-top: 1px solid #f5f5f5;
              }
  
              &:last-child {
                padding-bottom: 15px;
              }
  
              .media {
                position: relative;
  
                img {
                  width: 40px;
                  position: relative;
                }
  
                .status-circle {
                  left: 0;
                }
  
                .media-body {
                  >span {
                    font-weight: 500;
                    font-family: roboto;
                  }
  
                  p {
                    margin-bottom: 4px;
                  }
                }
              }
            }
  
            &:before,
            &:after {
              right: 10px !important;
              left: unset !important;
            }
          }
  
          .bookmark-flip {
            width: unset !important;
            box-shadow: unset !important;
            background-color: transparent !important;
            overflow: initial;
  
            .Typeahead-menu {
              top: 38%;
              background: transparent;
              box-shadow: none;
              left: -1px;
              overflow: auto;
              max-height: 93px;
            }
  
            .back {
              li {
                &:first-child {
                  svg {
                    position: relative;
                    width: 16px;
                    height: 16px;
                    top: -3px;
                    right: 0;
                    opacity: 1;
                  }
                }
              }
            }
  
            .ProfileCard-realName {
              text-align: left;
            }
  
            .ProfileCard-avatar {
              svg {
                color: $theme-body-font-color !important;
              }
            }
  
            .bookmark-dropdown {
              .bookmark-content {
                text-align: center;
  
                .bookmark-icon {
                  width: 52px;
                  height: 52px;
                  background-color: #f9f9f9;
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                }
              }
            }
          }
  
          .flip-card {
            width: 300px;
            height: 240px;
            background-color: transparent;
  
            .flip-card-inner {
              position: relative;
              height: 100%;
              text-align: center;
              transition: transform 0.6s;
              transform-style: preserve-3d;
  
              .front,
              .back {
                position: absolute;
                backface-visibility: hidden;
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
                width: 300px;
                height: 240px;
  
                button {
                  background-color: $transparent-color !important;
                  color: var(--theme-default);
                  padding: 0;
                  border: none;
                  text-decoration: underline;
                  text-transform: uppercase;
                  letter-spacing: 0.3px;
                  font-family: Roboto, sans-serif;
  
                  &:focus {
                    outline: $transparent-color;
                  }
                }
              }
  
              .front {
                background-color: $white;
                transition: 0.3s;
  
                .bookmark-dropdown {
                  .row {
                    >div {
                      position: relative;
                    }
                  }
  
                  li {
                    &:nth-child(2) {
                      max-height: 126px;
                      overflow: auto;
                    }
  
                    &:last-child {
                      width: 100%;
                      border-top: unset;
                    }
                  }
                }
  
                svg {
                  stroke: $dark-color;
                  transition: all 0.3s ease;
  
                  &:hover {
                    stroke: var(--theme-default);
                    transition: all 0.3s ease;
                  }
                }
              }
  
              .back {
                background-color: $white;
                transform: rotateY(180deg);
  
                li {
                  &:first-child {
                    margin-top: 20px;
                    height: 125px !important;
                  }
  
                  &:last-child {
                    padding: 15px;
                    border-top: unset;
                  }
                }
  
                button {
                  width: 100%;
                }
  
                .flip-back-content {
                  input {
                    border-radius: 25px;
                    padding: 6px 12px;
                    width: 100%;
                    border: 1px solid #ddd;
                  }
                }
              }
  
              &.flipped {
                transform: rotateY(180deg);
  
                .front {
                  opacity: 0;
                }
              }
            }
          }
  
          .notification-box {
            position: relative;
  
            svg {
              animation: tada 1.5s ease infinite;
            }
          }
  
          .cart-box {
            position: relative;
  
            svg {
              animation: swing 1.5s ease infinite;
            }
          }
  
          .chat-dropdown {
            li {
              +li {
                margin-top: 0 !important;
              }
  
              .media {
                .media-body {
                  p.font-primary {
                    font-size: 11px;
                  }
                }
              }
  
              .badge {
                font-family: Roboto, sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 1.3;
                letter-spacing: 0.5px;
                padding: 3px 6px;
                position: relative;
                right: unset;
                top: unset;
              }
            }
          }
  
          .cart-dropdown {
            .dropdown-title {
              padding: 20px;
              text-align: center;
              border-bottom: 1px solid rgba(145, 142, 153, 0.3);
            }
  
            .view-cart {
              text-decoration: underline;
              text-transform: uppercase;
              letter-spacing: 0.3px;
              font-family: Roboto, sans-serif;
            }
  
            .close-circle {
              a {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
  
                svg {
                  width: 14px;
                  stroke: $white;
                }
              }
            }
  
            .total {
              margin: 0;
              padding: 15px;
              background-color: #f9f9f9;
              border: none;
  
              h6 {
                font-family: Roboto, sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.5px;
              }
            }
  
            li {
              margin: 0 15px;
              padding: 10px 0;
  
              .media {
                align-items: center;
  
                .media-body {
                  a {
                    >span {
                      color: $theme-body-font-color;
                      font-weight: 400;
                      letter-spacing: 0;
                    }
                  }
                }
              }
  
              &+li {
                padding-bottom: 10px;
                margin-top: 0;
              }
  
              &:last-child {
                padding: 20px 15px;
                border-top: none;
              }
            }
  
            .qty-box {
              margin: 5px 0;
  
              .input-group {
                width: 80px;
                background-color: $transparent-color;
  
                input {
                  background-color: $transparent-color;
                  font-size: 10px;
                  font-family: Roboto, sans-serif;
                }
  
                .input-group-text {
                  background-color: $transparent-color;
                  border: unset;
                  padding: 0;
                }
  
                .btn {
                  padding: 0px 5px;
                  background-color: #f7f6ff !important;
                  border-color: #f7f6ff !important;
                  border-radius: 100%;
                  font-size: 14px;
                  color: $theme-body-font-color;
  
                  svg {
                    font-weight: 700;
                  }
                }
              }
            }
  
            p {
              line-height: 1.3;
              margin-top: 3px;
              opacity: 0.6;
            }
  
            .close-circle {
              position: absolute;
              right: 0;
              top: 0;
  
              svg {
                width: 14px;
              }
            }
          }
  
          .notification-dropdown {
            top: 52px;
            width: 300px;
            right: -20px !important;
            left: unset;
  
            li {
              background-color: #f9f9f9;
              border-radius: 5px;
  
              +li {
                border-top: unset;
              }
  
              &:last-child {
                text-align: center;
                background-color: transparent;
  
                a {
                  text-decoration: underline;
                  text-transform: uppercase;
                  letter-spacing: 0.3px;
                  font-family: Roboto, sans-serif;
                }
              }
  
              p {
                display: flex;
                align-items: center;
                justify-content: space-between;
  
                span {
                  font-size: 11px;
                }
              }
            }
  
            &:before,
            &:after {
              right: 28px !important;
              left: unset !important;
            }
          }
  
          .profile-dropdown {
            width: 160px;
            top: 25px;
            left: -12px;
            padding: 0 10px;
  
            li {
              padding: 10px !important;
  
              +li {
                margin-top: 0;
              }
  
              &:hover {
                a {
                  span {
                    color: var(--theme-default);
                    transition: color 0.3s ease;
                  }
  
                  svg {
                    stroke: var(--theme-default);
                    transition: stroke 0.3s ease;
  
                    path {
                      stroke: var(--theme-default);
                      transition: stroke 0.3s ease;
                    }
                  }
                }
              }
  
              &:last-child {
                padding-top: 10px !important;
              }
  
              span {
                color: $dark-color;
              }
  
              svg {
                width: 16px;
                vertical-align: bottom;
                margin-right: 10px;
                stroke: $dark-color;
              }
            }
          }
        }
      }
    }
  
    .page-body-wrapper {
      background-color: $body-color;
  
      .page-body {
        min-height: calc(100vh - #{$header-size});
        margin-top: $header-size;
        padding: $page-body-padding;
        position: relative;
        transition: 0.5s;
  
  
        .zoomout-enter-done {
          animation: zoomOut 1000ms ease-in-out;
        }
  
        .slidefade-enter-done {
          animation: slideInLeft 0.5s linear;
        }
  
        .fade-enter-done {
          animation: fadeIn 1000ms ease-in-out;
        }
  
        .fadebottom-enter-done {
          animation: fadeInDown 1000ms ease-in-out; 
  
        }
  
        .slidefade-enter,
        .slidefade-exit,
        .fade-enter,
        .fade-exit,
        .zoomout-enter,
        .zoomout-exit,
        .fadebottom-enter,
        .fadebottom-exit,
        .none-enter-done,
        .none-enter,
        .none-exit {
          animation: unset;
        }
      }
  
      &:before {
        animation: fadeIn 0.5s ease-in-out;
      }
  
  
      .page-title {
        padding-top: $page-title-padding;
        padding-bottom: $page-title-padding;
  
        >.row {
          .col-6 {
            &:first-child {
              display: flex;
  
              h3 {
                padding-right: 20px;
              }
            }
          }
        }
  
        .row {
          align-items: center;
  
          h3 {
            font-size: $page-title-font-size;
            margin-bottom: $page-title-margin-bottom;
            text-transform: $page-title-text-tranform;
  
            small {
              display: $page-small-title-display;
              font-size: $page-small-title-font-size;
              margin-bottom: $page-small-title-margin-bottom;
              text-transform: $page-small-title-text-transform;
              color: $theme-body-sub-title-color;
            }
          }
        }
  
        .breadcrumb {
          background-color: $transparent-color;
          padding: 0;
          margin-top: 5px;
          margin-bottom: 0;
          justify-content: flex-end;
  
          li {
            font-size: 13px;
            letter-spacing: 1px;
            font-family: $font-roboto, $font-serif;
          }
  
          .breadcrumb-item {
            &+.breadcrumb-item {
              &:before {
                content: $breadcrumb-content;
              }
            }
  
            a {
              color: var(--theme-default);
  
              svg {
                width: 14px;
                height: 14px;
                vertical-align: text-top;
              }
            }
          }
        }
      }
    }
  
    &.horizontal-wrapper {
      &.enterprice-type {
        .page-body-wrapper {
          .sidebar-wrapper {
            .badge {
              left: unset;
              right: 0;
            }
          }
        }
      }
      &.material-type{
        .page-body-wrapper{
          .sidebar-wrapper{
            .sidebar-main{
              .sidebar-links{
                > li{
                  > a{
                    .badge{
                      right: 0;
                      left: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .header-logo-wrapper {
        width: 150px;
  
        .toggle-sidebar {
          display: none;
        }
      }
  
      .page-body-wrapper {
        .sidebar-wrapper {
          height: 54px;
          line-height: inherit;
          top: 81px;
          background: $white;
          box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
          overflow-x: hidden;
          z-index: 1;
          overflow-y: visible;
          border-radius: 0;
          position: fixed;
          width: calc(100vw - 0px);
          border-top: 1px solid $light-semi-gray;
  
          .badge {
            position: absolute;
            top: 0;
            left: 48px;
            z-index: 1;
          }
  
          .logo-wrapper,
          .logo-icon-wrapper {
            display: none;
          }
  
          .sidebar-main {
            text-align: left;
            position: relative;
  
            #sidebar-menu {
              transition: 0.5s;
            }
  
            .left-arrow,
            .right-arrow {
              position: absolute;
              top: 0px;
              padding: 15px;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              cursor: pointer;
  
              &.disabled {
                display: none;
              }
            }
  
            .left-arrow {
              left: 0;
            }
  
            .right-arrow {
              right: 0;
            }
  
            .sidebar-links {
              list-style: none;
              margin: 0;
              padding: 0;
              line-height: normal;
              direction: ltr;
              text-align: left;
              display: -webkit-box !important;
              padding: 0 5px;
              transition: all 0.5s ease;
              white-space: nowrap;
  
              li {
                &.sidebar-list {
                  &:hover {
                    a {
                      &.active {
                        svg {
                          stroke: var(--theme-default);
                        }
                      }
                    }
  
                    .sidebar-submenu {
                      display: block !important;
                    }
                  }
  
                  a {
                    &.active {
                      .according-menu {
                        i {
                          &:before {
                            content: "-";
                          }
                        }
                      }
                    }
  
                    .according-menu {
                      display: none;
                    }
                  }
  
                  ul.sidebar-submenu {
                    li {
                      a {
                        &.active {
                          color: var(--theme-default);
  
                          &:after {
                            background-color: var(--theme-default);
                          }
                        }
                      }
                    }
                  }
                }
              }
  
              >li {
                position: relative;
                float: left;
                display: block;
                padding-top: 10px;
                padding-bottom: 10px;
  
                &:hover {
                  >a {
                    >span {
                      color: var(--theme-default);
                      transition: all 0.3s ease;
                    }
                  }
  
                  a {
                    background-color: rgba($primary-color, 0.15%);
                    transition: all 0.3s ease;
  
                    svg {
                      fill: rgba($primary-color, 0.1%);
                      stroke: var(--theme-default);
                      transition: all 0.3s ease;
                    }
                  }
                }
  
                &:first-child {
                  display: none !important;
                }
  
                >a {
                  display: block;
                  color: $theme-body-font-color;
                  line-height: 23px;
                  text-decoration: none;
                  padding: 5px 15px;
                  margin: 0 3px;
                  letter-spacing: 0.04em;
                  position: relative;
                  border-radius: 5px;
                  font-weight: 500;
                  transition: all 0.3s ease;
  
                  &.active {
                    background-color: lighten($primary-color, 22%);
                    transition: all 0.3s ease;
  
                    svg,
                    span {
                      color: var(--theme-default);
                      transition: all 0.3s ease;
                    }
                  }
  
                  .badge {
                    position: absolute;
                    top: -8px;
                    margin-left: 1px;
                    right: 0;
                    left: unset;
                    display: inline-table;
                  }
  
                  svg {
                    width: 16px;
                    vertical-align: bottom;
                    margin-right: 10px;
                    stroke-width: 2.5px;
                  }
                }
  
                .sidebar-submenu {
                  position: fixed;
                  z-index: 4;
                  box-shadow: 0 0 1px 0 $dark-gray;
                  padding: 15px 0px 15px 0px;
                  background: white;
  
                  li {
                    border: 0;
                    float: none;
                    position: relative;
                    display: block;
  
                    a {
                      background: transparent;
                      padding: 4px 30px;
                      display: block;
                      transition: all 0.5s ease;
                      line-height: 1.9;
                      color: rgba(43, 43, 43, 0.6);
                      letter-spacing: 0.06em;
                      font-weight: 500;
                      font-family: Roboto;
                      transition: all 0.3s ease;
  
                      &:after {
                        left: 20px;
                        @extend %horizontal-after;
                      }
  
                      &:hover {
                        color: var(--theme-default);
                        transform: translate(5px, 0px);
                        transition: all 0.3s ease;
  
                        &:after {
                          background-color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
  
                .mega-menu-container {
                  position: absolute;
                  right: -24%;
                  z-index: 4;
                  box-shadow: 0 0 1px 0 $dark-gray;
                  padding: 30px;
                  background: white;
                  width: 95%;
                  border-radius: 4px;
  
                  .mega-box {
                    width: 20%;
  
                    .link-section {
                      .submenu-title {
                        h5 {
                          text-transform: uppercase;
                          font-size: 14px;
                          margin-left: -13px;
                        }
                      }
  
                      .submenu-content {
                        box-shadow: none;
                        background-color: transparent;
                        position: relative;
                        display: block;
                        padding: 0;
  
                        li {
                          border: 0;
                          float: none;
                          position: relative;
                          display: block;
  
                          a {
                            border: 0 !important;
                            background: transparent;
                            color: $theme-body-font-color;
                            border-radius: 0 !important;
                            padding: 3px 0;
                            font-size: 13px;
                            display: block;
                            letter-spacing: 0.07em;
                            line-height: 1.9;
                            position: relative;
                            transition: all 0.3s ease;
  
                            &:hover {
                              color: var(--theme-default);
                              transform: translate(5px, 0px);
                              transition: all 0.3s ease;
  
                              &:after {
                                color: var(--theme-default);
                                transition: all 0.3s ease;
                              }
                            }
  
                            &:after {
                              position: absolute;
                              content: "";
                              left: -12px;
                              top: 50%;
                              width: 5px;
                              height: 1px;
                              background-color: $light-semi-font;
                            }
                          }
                        }
                      }
                    }
                  }
                }
  
                &:hover {
                  >a {
                    &:after {
                      left: 0;
                      right: 0;
                      opacity: 1;
                    }
                  }
                }
  
                &.mega-menu {
                  position: unset;
                }
              }
            }
          }
  
          &.overlay-white {
            background-color: rgba(255, 255, 255, 0.08);
          }
        }
  
        .toggle-nav {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
  
          i {
            font-size: 24px;
          }
        }
  
        .page-body {
          margin-top: 100px;
        }
  
        .sidebar-main-title {
          display: none !important;
        }
  
        &.scorlled {
          .sidebar-wrapper {
            display: none;
            transition: all 0.3s ease;
          }
        }
      }
  
      .page-header {
        width: 100%;
        box-shadow: none;
  
        .header-wrapper {
          .form-control-plaintext {
            width: auto !important;
          }
        }
      }
    }
  
    .sidebar-main-title {
      >div {
        background-color: rgba($primary-color, 0.06);
        padding: 15px 17px;
        border-radius: 10px;
      }
  
      h6 {
        color: var(--theme-default);
        margin-bottom: 5px;
        letter-spacing: 0.4px;
      }
  
      p {
        color: $dark-color;
        font-size: 11px;
        margin-bottom: 0;
        line-height: 1;
      }
    }
  
    // left sidebar css
    &.compact-wrapper {
      .page-header {
        margin-left: 280px;
        width: calc(100% - 280px);
  
        &.close_icon {
          margin-left: 90px;
          width: calc(100% - 90px);
        }
  
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
  
          .toggle-sidebar {
            display: none;
          }
        }
      }
  
      .page-body-wrapper {
        div {
          &.sidebar-wrapper {
            position: fixed;
            top: 0;
            z-index: 9;
            height: auto;
            line-height: inherit;
            background: $white;
            width: 280px;
            text-align: left;
            transition: 0.3s;
            box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);
  
            .logo-wrapper,
            .logo-icon-wrapper {
              padding: 25px 30px;
              box-shadow: -9px 0 20px rgba($semi-dark, 0.1);
            }
  
            .logo-icon-wrapper {
              display: none;
            }
  
            &.close_icon {
              width: auto;
              transition: all 0.3s ease;
  
              .badge {
                opacity: 0;
                width: 0;
                padding: 0;
              }
  
              .sidebar-main {
                .sidebar-links {
                  >li {
                    max-width: 86px;
                    margin: 0;
                    transition: all 0.3s ease;
  
                    .sidebar-submenu {
                      a {
                        display: none;
                      }
                    }
  
                    a {
                      padding: 12px 14px;
                      transition: all 0.3s ease;
                    }
                  }
                }
              }
  
              .sidebar-main-title {
                display: none !important;
                opacity: 0;
                transition: all 0.3s ease;
              }
  
              .logo-wrapper {
                display: none;
              }
  
              .logo-icon-wrapper {
                text-align: right;
                display: block;
              }
  
              &:hover {
                width: 280px;
                transition: all 0.3s ease;
  
                .badge {
                  opacity: 1;
                  width: auto;
                  transition: all 0.3s ease;
                }
  
                .sidebar-main {
                  .sidebar-links {
                    >li {
                      max-width: unset;
                      margin: initial;
                      transition: all 0.3s ease;
  
                      .sidebar-submenu {
                        a {
                          display: block;
                        }
                      }
  
                      >a {
                        padding: 12px 15px;
                        transition: all 0.3s ease;
  
                        span {
                          display: inline-block;
                        }
                      }
                    }
                  }
                }
  
                .sidebar-main-title {
                  opacity: 1;
                  display: block !important;
                  transition: all 0.3s ease;
                }
  
                .logo-wrapper {
                  display: block;
                }
  
                .logo-icon-wrapper {
                  display: none;
                }
              }
  
              .mobile-sidebar {
                transform: translate(183px);
              }
  
              ~.page-body {
                margin-left: 100px;
                transition: 0.5s;
              }
  
              ~footer {
                margin-left: 92px;
                transition: 0.5s;
              }
  
              ~.footer-fix {
                width: calc(100% - 90px);
              }
  
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 118px);
  
                  >li {
                    .sidebar-link {
                      &::before {
                        right: 20px;
                        left: unset;
                      }
                    }
  
                    a {
                      span {
                        display: none;
                      }
                    }
                  }
                }
              }
  
              .according-menu {
                display: none;
              }
            }
  
            .sidebar-main {
  
              .left-arrow,
              .right-arrow {
                display: none;
              }
  
              .sidebar-links {
                height: $sidebar-height;
                left: -300px;
                z-index: 99;
                transition: color 1s ease;
                overflow: auto;
                color: rgba(0, 0, 0, 0);
                margin-top: 20px;
                margin-bottom: 30px;
  
                &.custom-scrollbar {
                  &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px $transparent-color;
                  }
  
                  &::-webkit-scrollbar-thumb,
                  &::-webkit-scrollbar {
                    width: 6px;
                    border-radius: 13px;
                  }
  
                  &::-webkit-scrollbar-thumb {
                    box-shadow: inset 0 0 0 10px;
                    background-color: $white;
                  }
                }
  
                &:hover {
                  &.custom-scrollbar {
                    color: rgba(68, 102, 242, 0.1);
                  }
                }
  
                .sidebar-list {
                  ul.sidebar-submenu {
                    li {
                      a {
                        &.active {
                          color: var(--theme-default);
  
                          &:after {
                            border-color: var(--theme-default);
                          }
                        }
  
                        .according-menu {
                          top: 7px;
                        }
                      }
                    }
                  }
                }
  
                li {
                  a {
                    font-weight: 500;
  
                    span {
                      letter-spacing: 0.7px;
                      font-family: Roboto;
                      color: $dark-color;
                    }
  
                    &.link-nav {
                      .according-menu {
                        display: none;
                      }
                    }
                  }
  
                  &:last-child {
                    border-bottom: none;
                  }
                }
  
                &.opennav {
                  left: 0;
                }
  
                .back-btn {
                  display: block;
                  width: 100%;
                  padding: 0;
  
                  .mobile-back {
                    padding: 20px;
                    color: $theme-font-color;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-bottom: 1px solid #efefef;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                  }
                }
  
                >li {
                  display: block;
                  width: 100%;
                  padding: 0 20px;
                  position: relative;
  
                  &.sidebar-main-title {
                    padding-top: 15px;
                    padding-bottom: 13px;
                  }
  
                  &.sidebar-list {
                    .badge {
                      position: absolute;
                      right: 32px;
                      top: 14.5px;
                      padding: 0.44em 0.7em 0.32em;
                      z-index: 1;
                    }
  
                    &:hover {
                      >a {
                        &:hover {
                          background-color: rgba($primary-color, 0.12%);
                          transition: all 0.3s ease;
                        }
                      }
                    }
                  }
  
                  &:hover {
                    .sidebar-link:not(.active) {
                      &:hover {
                        svg {
                          fill: rgba($primary-color, 0.1%);
                          stroke: var(--theme-default);
                          transition: all 0.3s ease;
                        }
  
                        span {
                          color: var(--theme-default);
                          transition: all 0.3s ease;
                        }
  
                        .according-menu {
                          i {
                            color: var(--theme-default);
                            transition: all 0.3s ease;
                          }
                        }
                      }
                    }
                  }
  
                  a {
                    padding: 12px 15px;
                    position: relative;
                    color: #222222;
                    transition: all 0.3s ease;
  
                    svg {
                      width: 18px;
                      margin-right: 10px;
                      vertical-align: bottom;
                      float: none;
                      stroke-width: 2.3px;
                      color: $dark-color;
                      transition: all 0.3s ease;
                    }
  
                    .sub-arrow {
                      right: 20px;
                      position: absolute;
                      top: 10px;
  
                      i {
                        display: none;
                      }
  
                      &:before {
                        display: none;
                      }
                    }
                  }
  
                  &:first-child {
                    display: none !important;
                  }
  
                  .sidebar-submenu {
                    width: 100%;
                    padding: 0;
                    position: relative !important;
  
                    >li {
                      a {
                        padding-left: 40px !important;
                        font-weight: 400;
                      }
                    }
  
                    .submenu-title {
                      .according-menu {
                        top: 8px;
                      }
                    }
  
                    &.opensubmenu {
                      display: block;
                    }
  
                    li {
                      &:hover {
                        >a {
                          color: var(--theme-default);
                          transition: all 0.3s ease;
  
                          &:after {
                            border-top: 2px solid var(--theme-default);
                            transition: all 0.3s ease;
                          }
                        }
                      }
  
                      .nav-sub-childmenu {
                        display: none;
                        position: relative !important;
                        right: 0;
                        width: 100%;
                        padding: 0;
  
                        &.opensubchild {
                          display: block;
                        }
  
                        li {
                          a {
                            padding: 6px 45px;
                            padding-left: 56px !important;
  
                            &:after {
                              display: none;
                            }
                          }
                        }
                      }
  
                      a {
                        padding: 7px 25px;
                        font-size: 14px;
                        color: rgba($theme-body-font-color, 0.6);
                        display: block;
                        position: relative;
                        letter-spacing: 0.06em;
                        font-weight: 500;
                        font-family: Roboto;
                        transition: all 0.3s ease;
  
                        &:after {
                          left: 20px;
                          @extend %sidebar-after;
                        }
  
                        &:hover {
                          margin-left: 0;
                        }
                      }
                    }
                  }
  
                  &.mega-menu {
                    .sidebar-title {
                      &.active {
                        ~.mega-menu-container {
                          display: block !important;
                        }
                      }
                    }
                  }
  
                  .mega-menu-container {
                    padding: 0;
                    position: relative;
  
                    &.opensubmenu {
                      display: block;
                    }
  
                    .mega-box {
                      width: 100%;
                      padding: 0;
  
                      .link-section {
                        &:hover {
                          h5 {
                            color: var(--theme-default) !important;
                            transition: all 0.3s ease;
  
                            &:after {
                              border-top: 2px solid var(--theme-default);
                              transition: all 0.3s ease;
                            }
                          }
                        }
  
                        .submenu-title {
                          position: relative;
  
                          h5 {
                            margin-bottom: 0;
                            line-height: 1.9;
                            padding: 6px 25px 6px 45px;
                            font-size: 14px;
                            position: relative;
                            color: rgba(43, 43, 43, 0.6);
                            display: block;
                            letter-spacing: 0.06em;
                            font-weight: 500;
                            font-family: Roboto;
                            cursor: pointer;
  
                            &:after {
                              left: 25px;
                              @extend %sidebar-after;
                            }
                          }
  
                          .according-menu {
                            top: 8px;
                          }
                        }
  
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                margin-bottom: 0;
                                line-height: 1.9;
                                padding: 6px 25px 6px 45px;
                                font-size: 14px;
                                position: relative;
                                color: rgba(43, 43, 43, 0.6);
                                display: block;
                                letter-spacing: 0.06em;
                                font-weight: 500;
                                font-family: Roboto;
  
                                &:after {
                                  display: none;
                                }
  
                                &.active {
                                  color: var(--theme-default);
                                }
                              }
                            }
                          }
  
                          ul {
                            li {
                              a {
                                line-height: 1.9;
  
                                &:hover {
                                  margin-left: 0;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
  
                  .sidebar-link {
                    border-radius: 10px;
                    transition: all 0.5s ease;
                    display: block;
  
                    &.active {
                      background-color: lighten($primary-color, 22%);
                      transition: all 0.5s ease;
                      position: relative;
                      margin-bottom: 10px;
  
                      .according-menu {
                        i {
                          color: var(--theme-default);
                        }
                      }
  
                      svg {
                        color: var(--theme-default);
                        transition: all 0.3s ease;
                      }
  
                      span {
                        color: var(--theme-default);
                        transition: all 0.3s ease;
                      }
                    }
                  }
                }
              }
            }
          }
        }
  
        .mega-box {
          &.col {
            flex-basis: unset;
          }
        }
  
        .according-menu {
          position: absolute;
          right: 15px;
          top: 13px;
          display: block;
  
          i {
            vertical-align: middle;
            color: rgba($black, 0.8);
            font-weight: 600;
          }
        }
  
        .main-menu {
          &.border-section {
            border: none;
          }
  
          .menu-left {
            .main-menu-right {
              .toggle-nav {
                position: absolute;
                z-index: 2;
                right: 145px;
                top: 6px;
  
                .sidebar-name {
                  font-size: 18px;
                  padding-left: 5px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
  
        .page-body {
          min-height: calc(100vh - 80px);
          margin-top: 80px;
          margin-left: 280px;
        }
      }
  
      &.dark-sidebar,
      &.color-sidebar {
        .toggle-sidebar {
          svg {
            stroke: var(--theme-default);
          }
        }
  
        div.sidebar-wrapper {
          background-color: $dark-card-background !important;
  
          .sidebar-main {
            .sidebar-links {
              li {
                a {
                  span {
                    color: $dark-all-font-color;
                  }
  
                  svg {
                    stroke: $dark-all-font-color;
                  }
                }
              }
  
              >li {
                .mega-menu-container {
                  .mega-box {
                    .link-section {
                      .submenu-title {
                        h5 {
                          color: $dark-all-font-color;
                        }
                      }
  
                      .submenu-content {
                        &.opensubmegamenu {
                          li {
                            a {
                              color: $dark-all-font-color;
                              font-weight: 400;
                            }
                          }
                        }
                      }
                    }
                  }
  
                  &::after {
                    background-color: $light-all-font-color;
                  }
                }
  
                .sidebar-link {
                  &.active {
                    background-color: rgba($primary-color, 0.2);
  
                    svg {
                      color: var(--theme-default);
                      stroke: var(--theme-default);
                    }
                  }
                }
  
                .sidebar-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
  
              .sidebar-list {
                ul.sidebar-submenu {
                  li {
                    a {
                      span {
                        color: $dark-all-font-color;
                      }
  
                      &.active {
                        color: var(--theme-default);
                      }
                    }
                  }
                }
              }
            }
          }
  
          .logo-wrapper {
            .for-light {
              display: none;
            }
  
            .for-dark {
              display: block;
            }
          }
        }
  
        .according-menu {
          i {
            color: $dark-all-font-color;
          }
        }
  
        .sidebar-main-title {
          p {
            color: $dark-all-font-color;
          }
        }
      }
  
      &.color-sidebar {
        div.sidebar-wrapper {
          background-color: #2f3c4e;
        }
  
        .sidebar-main-title {
          p {
            display: none;
          }
  
          h6 {
            margin-bottom: 0;
          }
        }
      }
    }
  
    // compact icon css
    .bg-overlay1 {
      display: none;
    }
  
    &.compact-sidebar {
      .bg-overlay1 {
        transition: 0.8s;
        display: block;
        visibility: visible;
        opacity: 0;
  
        &.active {
          opacity: 1;
          z-index: 8;
          height: 100vh;
          width: 100vw;
          background-color: rgba($black, 0.2);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
  
      .toggle-sidebar {
        display: block !important;
        position: relative;
        right: unset;
        top: unset;
        margin-right: 15px;
      }
  
      .sidebar-main-title {
        display: none;
      }
  
      .logo-wrapper {
        display: none;
      }
  
      .logo-icon-wrapper {
        display: block;
        text-align: center;
        padding: 24px 30px;
        box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
      }
  
      .page-header {
        margin-left: 150px;
        width: calc(100% - 150px);
  
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
        }
  
        &.close_icon {
          margin-left: 0;
          width: calc(100% - 0px);
        }
      }
  
      .page-body-wrapper {
        .page-body {
          margin-top: 80px;
          margin-left: 150px;
        }
  
        .footer {
          margin-left: 150px;
        }
  
        div.sidebar-wrapper {
          position: fixed;
          top: 0;
          z-index: 9;
          background: $white;
  
          >div {
            height: auto;
            line-height: inherit;
            background: $white;
            width: 150px;
            transition: 0.3s;
            box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
          }
  
          .sidebar-main {
  
            .left-arrow,
            .right-arrow {
              display: none;
            }
  
            .sidebar-links {
              margin: 30px 0;
              height: calc(100vh - 113px);
              overflow: auto;
  
              li {
                a {
                  color: $dark-color;
  
                  span {
                    letter-spacing: 0.7px;
                    font-family: Roboto;
                    color: $dark-color;
                    font-size: 13px;
                  }
  
                  &.active {
                    background: rgba($primary-color, 0.1);
                    color: $primary-color;
                    border-radius: 20px;
                    margin: 0 10px;
  
                    span {
                      color: $primary-color;
                    }
  
                    ~.sidebar-submenu,
                    ~.mega-menu-container {
                      display: block;
                      animation: fadeInLeft 300ms ease-in-out;
                    }
                  }
                }
  
                .sidebar-title {
                  display: block;
                  padding: 20px 0;
                  border-bottom: 1px solid #f3f3f3;
                }
  
                .sidebar-submenu,
                .mega-menu-container {
                  position: fixed;
                  top: 0px;
                  height: calc(100vh - 0px);
                  overflow: auto;
                  left: 150px;
                  width: 230px;
                  background-color: $white;
                  box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
                  transition: all 0.5s ease-in-out;
                  display: none;
                  padding-top: 20px;
                  z-index: -1;
  
                  li {
                    a {
                      padding: 15px 15px 15px 40px;
                      display: block;
                      position: relative;
                      border-bottom: 1px solid #f1f1f1;
  
                      .sub-arrow {
                        display: none;
                      }
  
                      .according-menu {
                        position: absolute;
                        right: 20px;
                        top: 16px;
                      }
  
                      &.active {
                        background-color: rgba($primary-color, 0.1);
                        color: $primary-color;
                        transition: all 0.3s ease;
                      }
                    }
  
                    .submenu-content {
                      li {
                        a {
                          &.active {
                            background-color: $transparent-color;
                          }
                        }
                      }
                    }
                  }
  
                  >li {
                    >a {
                      &::after {
                        position: absolute;
                        content: "";
                        left: 20px;
                        top: 48%;
                        width: 6px;
                        border-top: 2px solid #595c60;
                      }
                    }
                  }
  
                  .mega-box {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
  
                    .link-section {
                      text-align: left;
  
                      .submenu-title {
                        h5 {
                          position: relative;
                          font-size: 14px;
                          font-weight: 400;
                          padding: 15px 15px 15px 40px;
                          letter-spacing: 1px;
                          margin-bottom: 0;
  
                          &::after {
                            position: absolute;
                            content: "";
                            left: 20px;
                            top: 48%;
                            width: 6px;
                            border-top: 2px solid #595c60;
                          }
                        }
                      }
                    }
                  }
                }
              }
  
              .sidebar-list {
                position: relative;
  
                .badge {
                  position: absolute;
                  left: 53%;
                  top: 7px;
                  font-weight: 500;
                  font-size: 9px;
                }
  
                .sidebar-link {
                  text-align: center;
  
                  svg {
                    display: block;
                    margin: 0 auto 6px;
                  }
  
                  >span {
                    display: block;
                    font-weight: 500;
                    letter-spacing: 1px;
                    font-size: 14px;
                  }
  
                  .according-menu {
                    display: none;
                  }
                }
              }
  
              .mega-menu {
                text-align: center;
                padding: 0;
  
                svg {
                  display: block;
                  margin: 0 auto 6px;
                }
  
                .according-menu {
                  position: absolute;
                  right: 20px;
                  top: 13px;
                }
              }
            }
          }
  
          &.close_icon {
            transform: translateX(-150px);
  
            ~.page-body,
            ~.footer {
              margin-left: 0px;
            }
          }
        }
      }
    }
  
    // compact small css
    &.compact-small {
      .toggle-sidebar {
        position: relative;
        right: unset;
        top: unset;
        margin-right: 15px;
      }
  
      .sidebar-main-title,
      .logo-wrapper {
        display: none;
      }
  
      .logo-icon-wrapper {
        display: block;
        text-align: center;
        padding: 27px 30px;
        box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
      }
  
      .page-header {
        margin-left: 90px;
        width: calc(100% - 90px);
  
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
        }
  
        &.close_icon {
          margin-left: 0;
          width: calc(100% - 0px);
        }
      }
  
      .page-body-wrapper {
        .page-body {
          margin-left: 90px;
        }
  
        .footer {
          margin-left: 90px;
        }
  
        div.sidebar-wrapper {
          >div {
            width: 90px;
          }
  
          .sidebar-main {
            .sidebar-links {
              li {
                .sidebar-title {
                  display: block;
                  padding: 15px 0;
                }
  
                .sidebar-submenu,
                .mega-menu-container {
                  left: 90px;
  
                  li {
                    a {
                      padding: 10px 15px 10px 40px;
  
                      .sub-arrow {
                        display: none;
                      }
  
                      .according-menu {
                        top: 11px;
                      }
                    }
                  }
                }
              }
  
              .sidebar-list {
                .badge {
                  display: none;
                }
  
                .sidebar-link {
                  svg {
                    display: block;
                    margin: 0 auto;
                    width: 20px;
                  }
  
                  >span {
                    display: none;
                  }
                }
              }
  
              .mega-menu {
                svg {
                  margin: 0 auto;
                }
  
                a {
                  >span {
                    display: none;
                  }
                }
              }
            }
          }
  
          &.close_icon {
            transform: translateX(-150px);
  
            ~.page-body,
            ~.footer {
              margin-left: 0px;
            }
          }
        }
      }
    }
  
    // only body css
    &.only-body {
      background-color: #f2f0fa;
  
      .page-body-wrapper {
        background-color: #f2f0fa;
      }
  
      .page-header {
        position: relative;
  
        .nav-menus {
          .profile-nav {
            .media-body {
  
              >span,
              >p {
                color: $white !important;
              }
            }
          }
  
          >li {
            >svg {
              stroke: $white;
            }
  
            >a {
              >svg {
                stroke: $white;
              }
            }
            .more_lang{
              >div,
              >span {
                .lang-txt {
                  color: $theme-body-font-color;
                }
              }
            }
            >div,
            >span {
              .lang-txt {
                color: $white;
              }
  
              i {
                color: $white;
              }
  
              svg {
                stroke: $white;
              }
            }
          }
        }
  
        .left-header,
        .right-header,
        .header-logo-wrapper {
          display: none;
        }
  
        .header-wrapper {
          padding: 0 !important;
  
          .nav-right {
            position: absolute;
            right: 60px;
            top: 58px;
          }
  
          .search-full {
            top: 37px;
            width: calc(100% - 50px);
            left: 25px;
  
            .form-group {
              .Typeahead {
                .u-posRelative {
                  background-color: $white;
                  border-radius: 15px;
                }
              }
            }
  
            .Typeahead-menu {
              top: 78px;
              width: calc(100% - 30px);
              margin: 0 auto;
              right: 0;
            }
          }
        }
      }
  
      .page-body-wrapper {
        .breadcrumb-item+.breadcrumb-item::before {
          color: $white;
        }
  
        .page-title {
          padding-top: 20px;
          padding-bottom: 20px;
  
          .breadcrumb {
            li {
              color: $white;
            }
          }
  
          .breadcrumb-item {
            a {
              svg {
                color: $white;
              }
            }
          }
  
          .row {
            h3 {
              color: $white;
            }
          }
        }
      }
  
      .sidebar-wrapper,
      footer {
        display: none;
      }
  
      .page-body-wrapper {
        .page-body {
          margin-top: 0px;
          min-height: calc(100vh - 0px);
        }
  
        .page-title {
          background: linear-gradient(26deg,
              var(--theme-default) 0%,
              #a26cf8 100%);
          padding-left: 30px;
          padding-right: 30px;
          margin-bottom: 30px;
          border-radius: 15px;
          margin-top: 30px;
  
          >.row {
            .col-6 {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
  
          .breadcrumb {
            margin-top: 5px;
            justify-content: flex-start;
          }
        }
      }
    }
  
    // modern css
    &.modern-type {
      .card {
        .cal-date-widget .cal-info h2 {
          color: $primary-color;
        }
      }
  
      .page-header {
        z-index: 100;
        margin-left: 0px;
        width: calc(100% - 0px);
  
        &.close_icon {
          margin-left: 0px;
          width: calc(100% - 0px);
        }
  
        .header-wrapper {
          .header-logo-wrapper {
            display: flex;
            align-items: center;
          }
  
          .logo-wrapper {
            display: block;
            margin-right: 30px;
          }
  
          .toggle-sidebar {
            display: block !important;
            position: relative;
            top: unset;
            right: unset;
            margin-right: 15px;
          }
        }
      }
  
      .page-body-wrapper {
        div.sidebar-wrapper {
          margin: 30px !important;
          top: 80px;
          bottom: 0;
          border-radius: 15px;
  
          .logo-wrapper,
          .logo-icon-wrapper {
            display: none;
          }
  
          .sidebar-main {
            .sidebar-links {
              height: calc(100vh - 160px);
            }
          }
  
          &.close_icon {
            left: -300px;
  
            .logo-icon-wrapper {
              display: none;
            }
  
            ~.page-body {
              margin-left: 0px;
            }
  
            ~footer {
              margin-left: 0px;
            }
  
            &:hover {
              .logo-wrapper {
                display: none;
              }
            }
          }
        }
  
        .page-body {
          margin-left: 310px;
  
          .container-fluid {
            max-width: 1450px;
          }
        }
  
        .footer {
          margin-left: 390px;
          margin-right: 0;
          border-radius: 10px;
        }
      }
  
      // react css
      .default-datepicker .react-datepicker .react-datepicker__day-name,
      .default-datepicker .react-datepicker .react-datepicker__day,
      .default-datepicker .react-datepicker .react-datepicker__time-name {
        width: 45px !important;
      }
    }
  
    // material css
    &.material-type {
      position: relative;
  
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: -50px;
        width: 100%;
        height: 300px;
        background-image: $gradient-primary;
      }
  
      .card {
        box-shadow: 0 0 20px rgba($black, 0.05);
      }
  
      .page-header {
        position: relative;
        max-width: 1560px;
        margin: 50px auto 0;
        border-radius: 15px 15px 0 0;
      }
  
      .page-body-wrapper {
        // background-image: url(../../images/other-images/boxbg.jpg);
        background-blend-mode: overlay;
        background-color: rgba($white, 0.5);
  
        .sidebar-wrapper {
          position: relative;
          max-width: 1560px;
          margin: 0 auto !important;
          top: unset;
          height: unset;
        }
  
        .page-body {
          margin: 0 auto;
          max-width: 1560px;
          background-color: $body-color;
          box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
          border-radius: 0 0 15px 15px;
        }
      }
  
      .footer {
        margin-top: 40px;
      }
    }
  
    &.advance-layout {
      background-blend-mode: overlay;
      background-color: rgba($body-color, 0.7);
  
      .card {
        border-radius: 15px;
        box-shadow: none;
        border: 1px solid #ecf3fa;
      }
  
      .page-header {
        position: relative;
        background-color: $white;
        margin: 0 auto;
      }
  
      .page-body-wrapper {
        background: transparent;
  
        .sidebar-wrapper {
          position: relative !important;
          top: unset !important;
          height: unset;
          box-shadow: none;
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
  
          >div {
            overflow-x: hidden;
          }
        }
  
        .page-body {
          margin-top: 0 !important;
        }
      }
    }
  
    &.material-type,
    &.advance-layout {
      .page-body-wrapper {
        .sidebar-wrapper {
          .sidebar-main {
            &.hovered {
              .sidebar-links {
                li {
                  &.sidebar-list {
                    &:hover {
                      .sidebar-submenu {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @keyframes pagebody {
    from {
      opacity: 0;
      transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  // Responsive menu css start //
  @media screen and (max-width: 1780px) {
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                >li {
                  .mega-menu-container {
                    right: -18%;
                    width: 91%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1480px) {
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                >li {
                  .mega-menu-container {
                    right: -18%;
                    width: 91%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1460px) {
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                >li {
                  .mega-menu-container {
                    right: -54%;
                    width: 75%;
                  }
                }
              }
            }
          }
        }
      }
    }
  
    .sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          >li {
            margin-right: 8px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1366px) {
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                >li {
                  .mega-menu-container {
                    right: -49%;
                    width: 70%;
                  }
                }
              }
            }
          }
        }
      }
    }
  
    .profile-dropdown {
      &.onhover-show-div {
        right: 0;
        left: unset !important;
  
        &:before,
        &:after {
          right: 10px !important;
          left: unset !important;
        }
      }
    }
  }
  
  @media screen and (max-width: 1199px) {
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            >ul {
              >li {
                padding: 6px;
              }
            }
          }
        }
      }
    }
  
    .left-header .mega-menu-container .submenu-title,
    .right-header .mega-menu-container .submenu-title {
      margin: 20px 20px 0 20px;
    }
  
    .mobile-title {
      &.d-none {
        display: flex !important;
        justify-content: space-between;
        padding: 20px 30px 17px;
        border-bottom: 1px solid #ddd;
        margin: 0 -15px;
  
        h5 {
          color: var(--theme-default);
        }
  
        svg {
          stroke: var(--theme-default) !important;
        }
      }
    }
  
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                >li {
                  .mega-menu-container {
                    right: -35%;
                    width: 56%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 992px) {
    .page-header {
      .header-wrapper {
        .nav-right {
          .notification-dropdown {
            top: 46px;
          }
        }
      }
    }
  
    .responsive-btn {
      display: none;
    }
  
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .sidebar-submenu {
                  width: 200px;
                  opacity: 0;
                  visibility: hidden;
                  border-radius: 5px;
                  transition: all 0.5s ease;
  
                  a {
                    .sub-arrow {
                      position: absolute;
                      right: 20px;
                      top: 5px;
                      color: $light-semi-font;
                      font-weight: 100;
                      transition: all 0.5s ease;
                      font-size: 10px;
                      top: 9px;
                    }
                  }
  
                  li {
                    .nav-sub-childmenu {
                      display: none;
                      border-radius: 4px;
                    }
  
                    &:hover {
                      .nav-sub-childmenu {
                        display: block !important;
                        position: absolute;
                        left: 200px;
                        background-color: $white;
                        top: -10px;
                        padding: 15px 0px 15px 0px;
                        box-shadow: 0 0 1px 0 #898989;
                      }
                    }
  
                    a {
                      &:hover {
                        .sub-arrow {
                          right: 16px;
                          transition: all 0.5s ease;
                        }
                      }
                    }
                  }
                }
  
                .mega-menu-container {
                  opacity: 0;
                  visibility: hidden;
                  transition: all 0.5s ease;
                }
  
                &:hover {
                  .sidebar-submenu {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 8px;
                    transition: all 0.5s ease;
                  }
  
                  .mega-menu-container {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 20px;
                    transition: all 0.5s ease;
                  }
                }
              }
            }
          }
  
          .footer {
            margin-left: 0;
          }
  
          .toggle-nav {
            display: none;
          }
  
          .mobile-back {
            display: none !important;
          }
        }
      }
    }
  }
  
  @media only screen and (min-width: 768px) {
  
    // material icon css
    .page-wrapper {
      &.material-icon {
        position: relative;
  
        &::before {
          position: absolute;
          content: "";
          left: 0;
          width: 100%;
          height: 240px;
          background: $gradient-primary;
        }
  
        .toggle-sidebar {
          color: rgba($white, 0.8);
  
          &:hover {
            >svg {
              color: $white !important;
              stroke: $white !important;
            }
          }
        }
  
        .left-header, .right-header {
  
          .mega-menu,
          .level-menu {
            .nav-link {
              background-color: rgba($white, 0.09);
              color: rgba($white, 0.7);
  
              svg {
                stroke: rgba($white, 0.7);
              }
            }
          }
        }
  
        .page-header {
          position: relative;
          background: transparent;
          padding-left: 90px;
          max-width: 1660px;
          margin: 0 auto;
          width: calc(100% - 0px);
  
          .header-wrapper {
            .nav-right {
              >ul {
                >li {
                  color: $white;
  
                  .notification-box,
                  .cart-box {
                    svg {
                      stroke: $white;
                    }
                  }
  
                  >a {
                    >svg {
                      stroke: $white;
                    }
                  }
  
                  >svg {
                    stroke: $white;
                  }
                }
              }
  
              .language-nav {
                .more_lang {
                  color: $theme-body-font-color;
                }
              }
  
              .onhover-show-div {
                color: $theme-body-font-color;
              }
  
              &.right-header {
                ul {
                  li {
                    .profile-media {
                      .media-body {
                        p {
                          color: rgba($white, 0.7);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
  
          &.close_icon {
            padding-left: 0;
            margin: 0 auto !important;
          }
        }
  
        .page-body-wrapper {
          .page-body {
            margin-top: 0 !important;
            padding-left: 90px;
            max-width: 1660px;
            margin: 0 auto;
          }
  
          div.sidebar-wrapper {
            &.close_icon {
              ~.page-body {
                padding-left: 15px;
                margin: 0 auto !important;
              }
            }
          }
  
          .page-title {
            color: $white;
  
            .breadcrumb {
              .breadcrumb-item {
                a {
                  svg {
                    color: $white;
                  }
                }
  
                &.active {
                  color: rgba($white, 0.6);
                }
  
                +.breadcrumb-item {
                  &::before {
                    color: rgba($white, 0.8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 767px) {
    .sidebar-wrapper {
      .menu {
        .brand-logo {
          img {
            width: 120px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 576px) {
    .toggle-nav {
      i {
        font-size: 20px;
      }
    }
  }
  
  /**=====================
      78. Sidebar CSS Ends
  ==========================**/